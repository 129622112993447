import moment from "moment";

let alphaRule = propertyType => {
  return v =>
    /[a-zA-Z ñÑáéíóúÁÉÍÓÚ]+$/.test(v) ||
    `Solo se permiten letras y espacios para el campo ${propertyType}`;
};

let required = propertyType => {
  return v => !!v || `El campo ${propertyType} es obligatorio`;
};

let selectRequired = propertyType => {
  return v => !!v || `El campo ${propertyType} es obligatorio`;
};

let selectRequiredAllowZero = propertyType => {
  return v =>
    (!!v && v != 0 && v != "0") || `El campo ${propertyType} es obligatorio`;
};

let fileRequired = propertyType => {
  return v => !!v || `El archivo ${propertyType} es obligatorio`;
  //return v => (v && v.length > 0) || `El archivo ${propertyType} es obligatorio`;
};

let minLength = (propertyType, minLength) => {
  return v =>
    v.length >= minLength ||
    `Utilice como mínimo ${minLength} caracteres para ${propertyType}`;
};

let maxLength = (propertyType, maxLength) => {
  return v =>
    v.length <= maxLength ||
    `Máximo número de caracteres sobrepasado  ${maxLength} para el campo ${propertyType}`;
};

let validEmail = propertyType => {
  let reg = /.+@.+\..+/;
  return v => reg.test(v) || `${propertyType} correo electrónico inválido`;
};

let dateFormat = propertyType => {
  //return v => moment(String(v)).format('DD/MM/YYYY') || 'Fecha Inválida'
  return v =>
    /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(v) ||
    `${propertyType} Fecha inválida`;
};

let onlyInteger = propertyType => {
  return v =>
    /[0-9]$/.test(v) || `${propertyType} Debe de ingresar un número entero`;
};

let minNumber = (propertyType, min) => {
  return v =>
    (v && v > min) ||
    `El número ingresado para ${propertyType} debe ser mayor a ${min}`;
};

//
let maxNumber = (propertyType, max) => {
  return v =>
    (v && v <= max) ||
    `El número ingresado para ${propertyType} debe ser menor o igual a ${max}`;
};

let cuiFormat = (propertyType, max) => {
  return v =>
    (v && v <= max) ||
    `El número ingresado para ${propertyType} debe ser menor a ${max}`;
};

//Que pueden ingresar números de telefono separados por comas
let phoneNumbers = propertyType => {
  return v =>
    !/[^0-9 .,()+]$/.test(v) ||
    `Solo se permiten valores numéricos,  ( ) y comas para el campo ${propertyType}`;
};

//Validar fecha, que sea mayor a la ingresada
let dateGreatherThan = (propertyType, date) => {
  return v =>
    moment(date, "DD-MM-YYYY").diff(moment(v, "DD-MM-YYYY")) < 0 ||
    `La fecha ingresada debe ser posterior a ${date}`;
};

//Validar fecha, que sea mayor o igual a la ingresada
let dateEqualGreatherThan = (propertyType, date) => {
  return v =>
    moment(date, "DD-MM-YYYY").diff(moment(v, "DD-MM-YYYY")) <= 0 ||
    `La fecha ingresada debe ser posterior o igual a ${date}`;
};


//Validar fecha, que sea anterior  a la ingresada
let dateBeforeThan = (propertyType, date) => {
  return v =>
    moment(date, "DD-MM-YYYY").diff(moment(v, "DD-MM-YYYY")) > 0 ||
    `La fecha ingresada debe ser anterior a ${date}`;
};

//Validar fecha, que sea anterior o igual a la ingresada
let dateBeforeEqualThan = (propertyType, date) => {
  return v =>
    moment(date, "DD-MM-YYYY").diff(moment(v, "DD-MM-YYYY")) >= 0 ||
    `La fecha ingresada debe ser anterior o igual a ${date}`;
};


//Validar 2 decimales
let decimals = (propertyType) => {
  return v =>
  /^\d+(\.\d{1,2})?$/.test(v) ||
    `Solo se permiten valores decimales para ${propertyType}`;
};


//Validar 4 decimales
let decimals4 = (propertyType) => {
  return v =>
    /^\d+(\.\d{1,4})?$/.test(v) ||
    `Solo se permiten valores decimales para ${propertyType}`;
};

//Validar 4 decimales
let decimals5 = (propertyType) => {
  return v =>
    /^\d+(\.\d{1,5})?$/.test(v) ||
    `Solo se permiten valores decimales para ${propertyType}. (5 decimales máximo)`;
};

//Validar 10 decimales
let decimals10 = (propertyType) => {
  return v =>
    /^\d+(\.\d{1,10})?$/.test(v) ||
    `Solo se permiten valores decimales para ${propertyType}. (10 decimales máximo)`;
};

//Validar 10 decimales con posibilidad de negativos
let negativeDecimals10 = (propertyType) => {
  return v =>
    /^-?\d+(\.\d{1,10})?$/.test(v) ||
    `Solo se permiten valores decimales para ${propertyType}. (10 decimales máximo)`;
};

//Validar latitud
let latitude = (propertyType) => {
  return v => 
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,16})?))$/.test(v) || `El valor de ${propertyType} no es una longitud correcta`;
  
  //isFinite(v) && Math.abs(v) <= 90 || `El valor de ${propertyType} no es una longitud correcta`;
};

//Validar longitud
let longitude = (propertyType) => {
  return v =>
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,16})?))$/.test(v)  || `El valor de ${propertyType} no es una longitud correcta`;
  //isFinite(v) && Math.abs(v) <= 180 || `El valor de ${propertyType} no es una longitud correcta`;
};

//Validar que no hayan comas
let notComma = propertyType => {
  return v =>
    /^[^,]+$/.test(v) || `No se permiten comas para el campo ${propertyType}.`;
};

export default {
  alphaRule,
  required,
  minLength,
  maxLength,
  validEmail,
  selectRequired,
  selectRequiredAllowZero,
  fileRequired,
  dateFormat,
  onlyInteger,
  minNumber,
  maxNumber,
  phoneNumbers,
  dateGreatherThan,
  dateEqualGreatherThan,
  dateBeforeThan,
  dateBeforeEqualThan,
  cuiFormat,
  decimals,
  decimals4,
  decimals5,
  decimals10,
  negativeDecimals10,
  latitude,
  longitude,
  notComma
};
